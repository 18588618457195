import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#EE403D",
                secondary: "#3D3533",
                black: "#231F20",
                white: "#FFF",
                rellena: "#231F20",
                levanta: "#FF883C", // F18642 --> old
                recubre: "#0699CE", // 0699D0 --> old
                coloca: "#EE403D",
                microconcretos: "#231F20",
                junteos: "#FF883C", // F18642 --> old
                estucos: "#0699CE", // 0699D0 --> old
                adhesivos: "#EE403D"
            },
        },
    },
});
 