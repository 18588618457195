<template>
    <v-footer class="mt-16" color="black" padless>
        <v-row id="footer" align="center" class="mx-16 mt-8 mb-12" justify="center">
            <v-col>
                <!-- MEZCLA BRAVA FOOTER LOGO -->
                <v-row justify="start">
                    <v-img
                        id="navbar-logo"
                        alt="Mezcla Brava"
                        class="shrink"
                        contain
                        src="../../assets/brand/Logo_MezclaBrava.png"
                        transition="scale-transition"
                    />
                </v-row>

                <v-row id="navbar-div" class="mt-6" justify="start">
                    <!-- INDEX COLUMN -->
                    <v-col class="pa-0" sm="12" xs="12" md="8" lg="8" xl="8">
                        <v-row justify="space-around" style="margin-left: 0; margin-top: 10px;">
                            <!-- FAMILY PRODUCTS INDEX -->
                            <v-col class="pa-0" sm="12" xs="12" md="5" lg="5" xl="5">
                                <!-- INDEX TITLE -->
                                <p class="index-title centuryGothicBold">PRODUCTOS</p>

                                <!-- MICRO CONCRETOS BUTTON -->
                                <v-row>
                                    <router-link class="nav_item mt-1" to="/family/microconcretos">
                                        <v-btn
                                            class="footer-item-label centuryGothic elevation-0 text-capitalize white--text"
                                            color="black"
                                            small
                                        >
                                            Micro Concretos
                                        </v-btn>
                                    </router-link>
                                </v-row>

                                <!-- JUNTEOS -->
                                <v-row>
                                    <router-link class="nav_item" to="/family/junteos">
                                        <v-btn
                                            class="footer-item-label centuryGothic elevation-0 text-capitalize white--text"
                                            color="black"
                                            small
                                        >
                                            Junteos
                                        </v-btn>
                                    </router-link>
                                </v-row>

                                <!-- ESTUCOS Y REPELLO BUTTON -->
                                <v-row>
                                    <router-link class="nav_item" to="/family/estucos">
                                        <v-btn
                                            class="footer-item-label centuryGothic elevation-0 text-capitalize white--text"
                                            color="black"
                                            small
                                        >
                                            Estucos y Repello
                                        </v-btn>
                                    </router-link>
                                </v-row>

                                <!-- ADHESIVOS Y NIVELANTES BUTTON -->
                                <v-row>
                                    <router-link class="nav_item" to="/family/adhesivos">
                                        <v-btn
                                            class="footer-item-label centuryGothic elevation-0 text-capitalize white--text"
                                            color="black"
                                            small
                                        >
                                            Adhesivos y Nivelantes
                                        </v-btn>
                                    </router-link>
                                </v-row>

                                <!-- CALCULADORA DE PRODUCTOS BUTTON -->
                                <v-row class="mb-8 mb-lg-0">
                                    <router-link class="nav_item" to="/calculadora">
                                        <v-btn
                                            class="footer-item-label centuryGothic elevation-0 text-capitalize white--text"
                                            color="black"
                                            small
                                        >
                                            Calculadora de Productos
                                        </v-btn>
                                    </router-link>
                                </v-row>
                            </v-col>

                            <!-- GENERAL ROUTES INDEX -->
                            <v-col class="pa-0" sm="12" xs="12" md="7" lg="7" xl="7">
                                <!-- INDEX TITLE -->
                                <p class="index-title centuryGothicBold">GENERAL</p>

                                <v-row>
                                    <v-col sm="12" xs="12" md="4" lg="4" xl="4">
                                        <!-- HOME BUTTON -->
                                        <v-row>
                                            <router-link class="nav_item" to="/">
                                                <v-btn
                                                    class="footer-item-label centuryGothic elevation-0 text-capitalize white--text"
                                                    color="black" small>
                                                    Inicio
                                                </v-btn>
                                            </router-link>
                                        </v-row>

                                        <!-- ABOUT US BUTTON -->
                                        <v-row>
                                            <router-link class="nav_item" to="/aboutUs">
                                                <v-btn
                                                    class="footer-item-label centuryGothic elevation-0 text-capitalize white--text"
                                                    color="black" small>
                                                    Nosotros
                                                </v-btn>
                                            </router-link>
                                        </v-row>

                                        <!-- DEALERS BUTTON -->
                                        <v-row>
                                            <router-link class="nav_item" to="/dealers">
                                                <v-btn
                                                    class="footer-item-label centuryGothic elevation-0 text-capitalize white--text"
                                                    color="black" small>
                                                    Distribuidores
                                                </v-btn>
                                            </router-link>
                                        </v-row>

                                        <!-- TUTORIALES BUTTON -->
                                        <v-row>
                                            <router-link class="nav_item" to="/academy">
                                                <v-btn
                                                    class="footer-item-label centuryGothic elevation-0 text-capitalize white--text"
                                                    color="black"
                                                    small
                                                >
                                                    Tutoriales
                                                </v-btn>
                                            </router-link>
                                        </v-row>
                                    </v-col>

                                    <v-col sm="12" xs="12" md="8" lg="8" xl="8">
                                        <!-- SCMB BUTTON -->
                                        <v-row>
                                            <router-link class="nav_item" to="/scmb">
                                                <v-btn
                                                    class="footer-item-label centuryGothic elevation-0 text-capitalize white--text"
                                                    color="black" small>
                                                    Sistema Constructivo
                                                </v-btn>
                                            </router-link>
                                        </v-row>

                                        <!-- CONTACT BUTTON -->
                                        <v-row>
                                            <router-link class="nav_item" to="/contact">
                                                <v-btn
                                                    class="footer-item-label centuryGothic elevation-0 text-capitalize white--text"
                                                    color="black" small>
                                                    Contacto
                                                </v-btn>
                                            </router-link>
                                        </v-row>

                                        <!-- PRIVACY POLICY BUTTON -->
                                        <v-row>
                                            <v-btn
                                                class="footer-item-label centuryGothic elevation-0 text-capitalize white--text"
                                                :href="privacy"
                                                color="black"
                                                download
                                                small
                                            >
                                                Aviso de Privacidad
                                            </v-btn>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- SOCIAL MEDIA ICONS COLUMN -->
                    <v-col class="pa-0 socialMediaColumn" sm="12" xs="12" md="4" lg="4" xl="4">
                        <v-row id="contactRow" align="start" justify="end">
                            <v-col sm="12" xs="12" md="1" lg="1" xl="1" align="center">
                                <!-- FACEBOOK ICON -->
                                <a href="https://www.facebook.com/mezclabrava" target="_blank">
                                    <v-icon
                                        class="mb-2 mr-4"
                                        dark
                                        large
                                    >mdi-facebook
                                    </v-icon>
                                </a>

                                <!-- YOUTUBE ICON -->
                                <a href="https://www.youtube.com/channel/UCW-1yYowI0hDZYNh4gkJI6A" target="_blank">
                                    <v-icon
                                        class="mb-2 mr-4"
                                        dark
                                        large
                                    >mdi-youtube
                                    </v-icon>
                                </a>

                                <!-- TWITTER ICON -->
                                <a href="https://twitter.com/mezclabrava" target="_blank">
                                    <v-icon
                                        class="mb-2 mr-4"
                                        dark
                                        large
                                    >mdi-twitter
                                    </v-icon>
                                </a>

                                <!-- LINKEDIN ICON -->
                                <a href="https://www.linkedin.com/company/mezcla-brava/" target="_blank">
                                    <v-icon
                                        class="mb-2 mr-4"
                                        dark
                                        large
                                    >mdi-linkedin
                                    </v-icon>
                                </a>
                            </v-col>

                            <v-col sm="12" xs="12" md="11" lg="11" xl="11">
                                <!-- CONTACT TITLE -->
                                <p class="index-title centuryGothicBold contactText">CONTACTO</p>

                                <!-- CONTACT MAIL -->
                                <p class="index-title centuryGothic contactText">
                                    <a href="mailto:atencion@mezclabrava.com" target="_blank">atencion@mezclabrava.com</a>
                                </p>

                                <v-row class="mr-0 mt-1 contactIconsRow">
                                    <!-- PHONE ICON -->
                                    <a href="tel:222 454 3945" target="_blank">
                                        <v-icon
                                            class="mr-2"
                                            dark
                                            large
                                        >mdi-phone
                                        </v-icon>
                                    </a>

                                    <!-- MAP ICON -->
                                    <a href="https://www.google.com/maps/search/?api=1&query=18.9927152,-98.2770322" target="_blank">
                                        <v-icon
                                            class="mr-2"
                                            dark
                                            large
                                        >mdi-map-marker
                                        </v-icon>
                                    </a>

                                    <!-- MAIL ICON -->
                                    <a href="mailto:atencion@mezclabrava.com" target="_blank">
                                        <v-icon
                                            dark
                                            large
                                        >mdi-email
                                        </v-icon>
                                    </a>
                                </v-row>

                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!-- COPYRIGHT ROW -->
                <v-row class="mt-16">
                    <!-- HORIZONTAL DIVIDER -->
                    <v-container class="pa-0" fluid>
                        <hr>
                    </v-container>

                    <!-- MEZCLA BRAVA COPYRIGHT LABEL -->
                    <v-row align="center" justify="space-around" class="mt-1">

                        <!-- COPY RIGHT -->
                        <v-col xl="6" lg="6" md="12" sm="12" xs="12">
                            <p class="index-title centuryGothicBold copyrightText">
                                <v-icon
                                    dark
                                    class="mt-n1"
                                >mdi-copyright
                                </v-icon>
                                Mezcla Brava
                            </p>
                        </v-col>

                        <!-- DEVELOPED BY ideen -->
                        <v-col xl="6" lg="6" md="12" sm="12" xs="12" align="end">
                            <p class="index-title centuryGothicBold copyrightText">
                                Desarrollado por <a href="https://ideen.mx" style="text-decoration: underline !important;">ideen</a> | México, 2024.
                            </p>
                        </v-col>
                    </v-row>
                </v-row>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            privacy: "/files/avisodeprivacidad.pdf"
        }
    }
}
</script>

<style scoped>
#navbar-logo {
    opacity: 1 !important;
    width: 120px;
}

.index-title {
    color: #FFFFFF;
}

.footer-item-label {
    color: #FFF;
    text-decoration: none !important;
    font-size: 15px;
}

a {
    text-decoration: none !important;
    color: white !important;
}

i:hover, a:hover, .v-btn:hover {
    color: #e6413d !important;
}

p {
    color: #FFF !important;
}

.contactText {
    text-align: end;
}

.contactIconsRow {
    justify-content: end;
}

@media (max-width: 960px) {
    .socialMediaColumn {
        margin-top: 40px;
    }

    #footer {
        margin: 50px 20px !important;
    }

    #navbar-div {
        padding: 0 10px;
    }

    #contactRow {
        margin-top: 10px;
        padding: 0 30px;
    }

    .contactText {
        text-align: center;
    }

    .contactIconsRow {
        justify-content: center;
    }

    .copyrightText {
        font-size: 0.9em;
    }
}
</style>