import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import x5GMaps from 'x5-gmaps';
Vue.use(x5GMaps, 'AIzaSyDCO4BQAcLJVViXcDGa9ugD2Iw_fQhmte4');

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App),
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')
