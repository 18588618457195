import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import('../views/home/Home')
const SCMB = () => import('../views/scmb/SCMB')
const Academy = () => import('../views/academy/Academy')
const AboutUs = () => import('../views/about_us/AboutUs')
const Dealers = () => import('../views/dealers/Dealers')
const Contact = () => import('../views/contact/Contact')
const Product = () => import('../views/product/Product')
const Calculator = () => import('../views/calculator/Calculator')
const ProductsFamily = () => import('../views/products_family/ProductsFamily')
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/home', name: 'Home', component: Home },
  { path: '/family/', name: 'Products Family Error', component: Home },
  { path: '/family/:familyId', name: 'Products Family', component: ProductsFamily },
  { path: '/product/', name: 'Product Error', component: Home },
  { path: '/product/:product', name: 'Product', component: Product },
  { path: '/scmb', name: 'SCMB', component: SCMB },
  { path: '/academy', name: 'Academy', component: Academy },
  { path: '/aboutUs', name: 'About Us', component: AboutUs },
  { path: '/dealers', name: 'Dealers', component: Dealers },
  { path: '/contact', name: 'Contact', component: Contact },
  { path: '/calculadora', name: 'Calculator', component: Calculator },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
