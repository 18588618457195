<template>
    <v-container fluid>
        <v-app-bar
            style="border-bottom: 10px solid #e6413d !important; "
            class="desktop-navbar"
            extension-height="10"
            color="black"
            extended
            outlined
            fixed
            dark
            flat
            app
        >
            <v-spacer class="hidden-sm-and-down"></v-spacer>

            <!-- MEZCLA BRAVA LOGO -->
            <div class="d-flex align-center">
                <router-link to="/">
                    <v-img
                        id="navbar-logo"
                        alt="Mezcla Brava"
                        class="shrink mr-2"
                        contain
                        src="../../assets/brand/Logo_MezclaBrava_Slogan.png"
                        transition="scale-transition"
                    />
                </router-link>
            </div>

            <v-spacer></v-spacer>

            <!-- HOME SCREEN -->
            <router-link class="nav_item hidden-sm-and-down" to="/">
                <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                    INICIO
                </v-btn>
            </router-link>

            <!-- ABOUT US SCREEN -->
            <router-link class="nav_item hidden-sm-and-down" to="/aboutUs">
                <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                    NOSOTROS
                </v-btn>
            </router-link>

            <!-- FAMILIES LIST -->
            <v-menu
                open-on-hover
                offset-y
                dark
            >
                <template v-slot:activator="{ on, attributes }">
                    <v-btn
                        class="navbar-item-label centuryGothicBold elevation-0 hidden-sm-and-down"
                        color="black"
                        v-bind="attributes"
                        v-on="on"
                        large
                    >
                        PRODUCTOS
                        <v-icon
                            dark
                            right
                        >mdi-chevron-down</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <!-- MICRO CONCRETOS -->
                    <v-list-item>
<!--                        <router-link  class="nav_item" to="/family/rellena">-->
                        <router-link  class="nav_item" to="/family/microconcretos">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                MICRO CONCRETOS
                            </v-btn>
                        </router-link>
                    </v-list-item>

                    <!-- JUNTEOS -->
                    <v-list-item>
                        <router-link  class="nav_item" to="/family/junteos">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                JUNTEOS
                            </v-btn>
                        </router-link>
                    </v-list-item>

                    <!-- ESTUCOS -->
                    <v-list-item>
                        <router-link  class="nav_item" to="/family/estucos">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                ESTUCOS Y REPELLO
                            </v-btn>
                        </router-link>
                    </v-list-item>

                    <!-- ADHESIVOS Y NIVELANTE -->
                    <v-list-item>
                        <router-link  class="nav_item" to="/family/adhesivos">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                ADHESIVOS Y NIVELANTES
                            </v-btn>
                        </router-link>
                    </v-list-item>

                    <!-- CALCULADORA DE PRODUCTOS -->
                    <v-list-item>
                        <router-link  class="nav_item" to="/calculadora">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                CALCULADORA DE PRODUCTOS
                            </v-btn>
                        </router-link>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- SCMB SCREEN -->
            <router-link class="nav_item hidden-sm-and-down" to="/scmb">
                <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                    SISTEMA CONSTRUCTIVO
                </v-btn>
            </router-link>

            <!-- TUTORIALS SCREEN -->
            <router-link class="nav_item hidden-sm-and-down" to="/academy">
                <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                    TUTORIALES
                </v-btn>
            </router-link>

            <!-- DEALERS SCREEN -->
            <router-link class="nav_item hidden-sm-and-down" to="/dealers">
                <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                    DISTRIBUIDORES
                </v-btn>
            </router-link>

            <!-- CONTACT SCREEN -->
            <router-link class="nav_item hidden-sm-and-down" to="/contact">
                <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                    CONTACTO
                </v-btn>
            </router-link>

            <!-- MOBILE NAVBAR MENU ICON BUTTON-->
            <v-btn
                class="hidden-md-and-up"
                color="white"
                fab
                icon
                @click.stop="drawer = !drawer"
            >
                <v-icon x-large>mdi-menu</v-icon>
            </v-btn>

            <v-navigation-drawer
                v-model="drawer"
                dark
                color="black"
                right
                temporary
                absolute
                height="100vh"
                app
                style="position: fixed; top: 0; right: 0; overflow-y: scroll"
            >
                <v-col align-self="end" align="end">
                    <!-- MOBILE NAVBAR MENU ICON BUTTON-->
                    <v-row justify="end" align="center">
                        <v-btn
                            class="hidden-md-and-up mt-1 mr-2"
                            color="white"
                            fab
                            icon
                            @click.stop="drawer = !drawer"
                        >
                            <v-icon x-large>mdi-close</v-icon>
                        </v-btn>
                    </v-row>

                    <hr class="mt-4 mb-4">

                    <!-- HOME SCREEN -->
                    <v-row align="end" justify="end">
                        <router-link class="nav_item hidden-md-and-up mb-2" to="/">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                INICIO
                            </v-btn>
                        </router-link>
                    </v-row>

                    <!-- ABOUT US SCREEN -->
                    <v-row align="end" justify="end">
                        <router-link class="nav_item hidden-md-and-up" to="/aboutUs">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                NOSOTROS
                            </v-btn>
                        </router-link>
                    </v-row>

                    <!-- FAMILIES LIST -->
                    <v-row align="end" justify="end">
                        <v-menu
                            open-on-hover
                            offset-y
                            dark
                        >
                            <template v-slot:activator="{ on, attributes }">
                                <v-btn
                                    class="navbar-item-label centuryGothicBold elevation-0 hidden-md-and-up"
                                    color="black"
                                    v-bind="attributes"
                                    v-on="on"
                                    large
                                >
                                    <v-icon
                                        dark
                                        left
                                    >mdi-chevron-down</v-icon>
                                    PRODUCTOS
                                </v-btn>
                            </template>

                            <v-list>
                                <!-- MICRO CONCRETOS -->
                                <v-list-item>
                                    <router-link  class="nav_item" to="/family/microconcretos">
                                        <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                            MICRO CONCRETOS
                                        </v-btn>
                                    </router-link>
                                </v-list-item>

                                <!-- JUNTEOS -->
                                <v-list-item>
                                    <router-link  class="nav_item" to="/family/junteos">
                                        <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                            JUNTEOS
                                        </v-btn>
                                    </router-link>
                                </v-list-item>

                                <!-- ESTUCOS -->
                                <v-list-item>
                                    <router-link  class="nav_item" to="/family/estucos">
                                        <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                            ESTUCOS Y REPELLO
                                        </v-btn>
                                    </router-link>
                                </v-list-item>

                                <!-- ADHESIVOS Y NIVELANTE -->
                                <v-list-item>
                                    <router-link  class="nav_item" to="/family/adhesivos">
                                        <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                            ADHESIVOS Y NIVELANTE
                                        </v-btn>
                                    </router-link>
                                </v-list-item>

                                <!-- CALCULADORA DE PRODUCTOS -->
                                <v-list-item>
                                    <router-link  class="nav_item" to="/calculadora">
                                        <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                            CALCULADORA DE PRODUCTOS
                                        </v-btn>
                                    </router-link>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-row>

                    <!-- SCMB SCREEN -->
                    <v-row align="end" justify="end">
                        <router-link class="nav_item hidden-md-and-up" to="/scmb">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                SISTEMA CONSTRUCTIVO
                            </v-btn>
                        </router-link>
                    </v-row>

                    <!-- TUTORIALS SCREEN -->
                    <v-row align="end" justify="end">
                        <router-link class="nav_item hidden-md-and-up" to="/academy">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                TUTORIALES
                            </v-btn>
                        </router-link>
                    </v-row>

                    <!-- DEALERS SCREEN -->
                    <v-row align="end" justify="end">
                        <router-link class="nav_item hidden-md-and-up" to="/dealers">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                DISTRIBUIDORES
                            </v-btn>
                        </router-link>
                    </v-row>

                    <!-- CONTACT SCREEN -->
                    <v-row align="end" justify="end">
                        <router-link class="nav_item hidden-md-and-up" to="/contact">
                            <v-btn class="navbar-item-label centuryGothicBold elevation-0" color="black" large>
                                CONTACTO
                            </v-btn>
                        </router-link>
                    </v-row>
                </v-col>
            </v-navigation-drawer>

            <v-spacer class="hidden-sm-and-down"></v-spacer>

        </v-app-bar>
    </v-container>
</template>

<script>
export default {
    name: "Navbar",
    data() {
        return {
            productsMenu: null,
            drawer: false,
        }
    },
}
</script>

<style scoped>

.desktop-navbar {
    padding-top: 10px;
    padding-bottom: 75px;
}

.desktop-navbar:hover {
    opacity: 1;
}

#navbar-logo {
    opacity: 1 !important;
    width: 120px;
}

.v-application a {
    text-decoration: none !important;
}

@media (max-width: 960px) {
    .desktop-navbar {
        padding-top: 10px;
        padding-bottom: 65px;
    }

    #navbar-logo {
        opacity: 1 !important;
        width: 100px;
    }
}
</style>