<template>
    <v-app>
        <Navbar></Navbar>

        <v-main class="pa-0 ma-0">
            <router-view :key="$route.path"/>
        </v-main>

        <Footer></Footer>
    </v-app>
</template>

<script>
import './styles/main.css';
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";

export default {
    name: 'App',
    components: {
        Navbar,
        Footer,
    },
};
</script>
